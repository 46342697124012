.breadcumbArea {
  min-height: 550px;
  padding-top: 92px;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    min-height: 400px;
    padding-top: 0px;
  }
  @media (max-width: 450px) {
    min-height: 300px;
    padding-top: 0px;
  }
  &::before {
    content: "";
    background: -moz-linear-gradient(
      left,
      rgba(14, 29, 90, 0.79) 8%,
      rgba(14, 29, 90, 0.79) 46%,
      rgba(14, 29, 90, 0.07) 95%,
      rgba(14, 29, 90, 0) 100%
    );
    background: -webkit-linear-gradient(
      left,
      rgba(14, 29, 90, 0.79) 8%,
      rgba(14, 29, 90, 0.79) 46%,
      rgba(14, 29, 90, 0.07) 95%,
      rgba(14, 29, 90, 0) 100%
    );
    background: linear-gradient(
      to right,
      rgba(14, 29, 90, 0.79) 8%,
      rgba(14, 29, 90, 0.79) 46%,
      rgba(14, 29, 90, 0.07) 95%,
      rgba(14, 29, 90, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#c9151a30', endColorstr='#00151a30', GradientType=1);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  .breadcumbWrap {
    h2 {
      font-size: 60px;
      font-weight: 700;
      margin-bottom: 10px;
      color: #fff;
      @media (max-width: 450px) {
        font-size: 36px;
      }
    }
    ul {
      li {
        display: inline-block;
        font-size: 18px;
        color: #b19e67;
        @media (max-width: 450px) {
          font-size: 16px;
        }
        &::before {
          content: "/";
          padding: 0px 5px;
          color: #fff;
        }
        &:first-child {
          &::before {
            display: none;
          }
        }
        a {
          color: #fff;
        }
      }
    }
  }
}
