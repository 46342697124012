.recentPostWrapper {
  padding: 30px;
  background: #f5f5f5;
  h3 {
    margin-bottom: 25px;
    font-size: 24px;
  }
  ul {
    li {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      &.postItem {
        overflow: hidden;
        .postImg {
          float: left;
          margin-right: 20px;
        }
        .postContent {
          overflow: hidden;
          a {
            color: #666;
            display: inline-block;
            &:hover {
              color: #b19e67;
            }
          }
        }
      }
    }
  }
}
