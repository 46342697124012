.pricingTableArea {
  padding: 100px 0px 70px;
  background: #f5f5f5;
  .pricingWrapper {
    text-align: center;
    background: #fff;
    padding: 40px 50px 50px;
    margin-bottom: 30px;
    transition: all 0.3s;
    @media (max-width: 1024px) {
      padding: 40px 40px 50px;
    }
    i {
      color: #b19e67;
      transition: all 0.3s;
    }
    h3 {
      margin-bottom: 20px;
      font-size: 18px;
      transition: all 0.3s;
      font-weight: 600;
    }
    h2 {
      font-size: 60px;
      font-family: "Open Sans", sans-serif;
      font-weight: 600;
      margin-bottom: 10px;
      transition: all 0.3s;
      margin-right: 20px;
    }
    p {
      font-size: 16px;
      line-height: 30px;
      margin-bottom: 25px;
      transition: all 0.3s;
      @media (max-width: 1024px) {
        font-size: 14px;
        line-height: 1.33;
      }
    }
    &:hover {
      background: #b19e67;
      i,
      h3,
      h2,
      p {
        color: #fff;
      }
      .btnStyle {
        button {
          background: #fff;
          color: #b19e67;
        }
      }
    }
  }
}
