.singleContentArea {
  .singleContentImg {
    margin-bottom: 50px;
    img {
      width: 100%;
    }
  }
  .singleContentWrap {
    h3 {
      font-size: 36px;
      margin-bottom: 25px;
    }
    h5 {
      font-size: 22px;
      font-family: "Open Sans", sans-serif;
      line-height: 40px;
      margin-bottom: 30px;
      @media (max-width: 576px) {
        line-height: 30px;
        font-size: 18px;
      }
    }
    p {
      font-size: 16px;
      line-height: 30px;
      margin-bottom: 35px;
      @media (max-width: 576px) {
        font-size: 14px;
      }
    }
    .avatarWra {
      margin-top: 15px;
      img {
        width: 100%;
        @media (max-width: 576px) {
          margin-bottom: 30px;
        }
      }
      h4 {
        font-size: 24px;
        margin-bottom: 20px;
      }
      ul {
        li {
          font-size: 15px;
          margin-bottom: 10px;
          &::before {
            content: "\f046";
            margin-right: 15px;
            font-family: "fontawesome";
            color: #b19e67;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
