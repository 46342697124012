@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700|Playfair+Display:400,700");

body {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  color: #333;
  @media (max-width: 1200px) {
    font-size: 14px;
  }
  p {
    color: #666;
    line-height: 1.33;
    @media (max-width: 1200px) {
      line-height: 1.33;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Playfair Display", serif;
    color: #282e3f;
  }
  ul {
    list-style-type: none;
    padding-left: 0;
    margin: 0;
  }
  a {
    text-decoration: none;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s;
    &:hover {
      text-decoration: none;
    }
  }
  img {
    max-width: 100%;
  }

  button,
  a:focus {
    outline: none;
  }
  .btnStyle {
    a,
    button {
      background-color: #b19e67;
      font-size: 16px;
      font-weight: 600;
      color: #fff;
      padding: 10px 38px;
      border: 2px solid transparent;
      text-transform: capitalize;
      display: inline-block;
      margin-top: 3px;
      @media (max-width: 1200px) {
        font-size: 14px;
        padding: 10px 35px;
      }
      @media (max-width: 768px) {
        padding: 10px 13px;
      }
      &:hover {
        background: #d4c291;
      }
    }
    &.btnStyle3 {
      a {
        position: relative;
        &::before {
          content: "";
          position: absolute;
          right: -60px;
          top: 50%;
          width: 85px;
          height: 2px;
          background: #fff;
          bottom: 0;
          transform: translateY(-50%);
          transition: all 0.3s;
          -webkit-transition: all 0.3s;
          -moz-transition: all 0.3s;
          @media (max-width: 1200px) {
            width: 60px;
            right: -40px;
          }
        }
        &:hover {
          &::before {
            right: -95px;
          }
        }
      }
    }
  }
  .bgFFF {
    background: #fff !important;
  }
  .pt100 {
    padding-top: 100px !important;
  }
}
